<template>
	<div>
		<OrderForms
			:is-redirect-form="isRedirectForm"
			:order-detail="orderDetail"
			:add-an-update="updateOrder"
			@submit="createOrder"
			@cancel="cancel"
		/>
		<CustomModal
			v-if="isDownloadErr"
			:submit-text="$t('Button.OK')"
			:is-show-cancel="false"
			@submit-modal="confirmDownloadErr"
			@cancel-modal="confirmDownloadErr"
		>
			<template #content>
				{{ message }}
			</template>
		</CustomModal>
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
import { CREATE_ORDER, GET_ORDER } from "../store/action-types"
import { RESET_ORDER_FILTERS, SET } from "../store/mutation-types"
import { GET_FORM_DETAIL } from "@/modules/forms/store/action-types"

const { mapActions, mapMutations, mapState } = createNamespacedHelpers("orders")
export default {
	name: "CreateOrder",
	components: {
		OrderForms: () => import("../components/OrderForms"),
	},
	props: {
		updateOrder: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isDownloadErr: false,
		}
	},
	computed: {
		...mapState(["orderDetail", "message"]),
		isRedirectForm() {
			const redirect = this.$route.query.formID
			if (redirect) {
				return true
			}
			return false
		},
		formDetail() {
			return this.$store.state.forms.formDetail
		},
		formID() {
			return this.$route.query.formID || null
		},
	},
	watch: {
		message(val) {
			if (val) {
				this.isDownloadErr = true
				this.$store.commit("set", ["modalShow", true])
			}
		},
	},
	beforeDestroy() {
		this.RESET_ORDER_FILTERS()
	},
	created() {
		if (this.updateOrder) {
			this.$route.meta.label = this.$t("OrdersTitle.NewUpdate")
			this.$route.meta.title = this.$t("OrdersTitle.NewUpdate")
		}
		this.getFormDetail()
		this.getOrderDetail()
	},
	mounted() {
		const body = document.getElementsByTagName("body")[0]
		body.scrollTop = 0
	},
	methods: {
		...mapActions({ CREATE_ORDER, GET_ORDER }),
		...mapMutations({ RESET_ORDER_FILTERS, SET }),
		async createOrder(params) {
			this.showLoading()
			if (params.files && params.files.length) {
				const files = params.files.map(el => el.file)
				// add new file
				await this.$store.dispatch("uploadFile", files).then(res => {
					if (res.ok) {
						delete params.files
						params["tokens"] = res.tokens
					}
				})
			}
			// create order
			const res = await this.CREATE_ORDER(params)
			if (res) {
				this.RESET_ORDER_FILTERS()
				this.$router.push({ name: "OrderManagement" })
			}
			this.hideLoading()
		},
		async getFormDetail() {
			if (this.isRedirectForm) {
				const params = {
					id: this.formID,
				}
				await this.$store.dispatch(`forms/${GET_FORM_DETAIL}`, params)
			}
		},
		async getOrderDetail() {
			if (!this.updateOrder) return
			const params = {
				data: {
					originalId: this.$route.query.original_order,
				},
				notLoading: true,
			}
			await this.GET_ORDER(params)
		},
		cancel() {
			// if (this.isRedirectForm) {
			// 	this.$router.push(`/forms/${this.formID}/update`)
			// } else if (this.updateOrder) {
			// 	const originalId = this.$route.query.original_order
			// 	this.$router.push(`/orders/${originalId}/detail`)
			// } else {
			// 	this.$router.push("/orders")
			// }
			this.$router.go(-1)
		},

		confirmDownloadErr() {
			this.SET(["message", null])
			this.isDownloadErr = false
			this.$store.commit("set", ["modalShow", false])
		},
	},
}
</script>

<style></style>
